.sidebar-menu {
    min-height: 100vh;
    height: 100%;

    .profile-tab {
        margin-bottom: 40px;

        .user-name {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            margin-top: 10px;
            color: #fff;
        }

        .account-type {
            font-size: 13px;
            font-weight: 500;
            opacity: 0.5;
            color: #fff;
        }
    }

    .user-stats {
        display: flex;

        .user-details {
            color: #fff;
            width: 50%;

            .stat-title {
                font-size: 15px;
                margin-bottom: 0px;
            }

            .stat-count {
                font-size: 30px;
                font-weight: 500;
            }

            &:last-child {
                padding-left: 10px;
            }
        }
    }

    >.nav {
        margin: 0 -10px;

        >.nav-item {
            padding: 10px;

            >.nav-link {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 125px;
                height: 125px;
                padding: 10px 10px 10px 17px;
                border-radius: 8px;
                background: #fff;
                box-shadow: 0 42px 60px 0 rgba(90, 113, 208, 0.11);
                z-index: 1;
                transition: 0.3s ease;
                transition-property: "box-shadow";

                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    border-radius: inherit;
                    z-index: 0;
                    opacity: 0;
                    background: linear-gradient(46deg, #0fadd0, #03bea5);
                    transition: 0.4s ease-in-out;
                }

                .menu-icons {
                    color: #c4c4c4;
                    font-size: 20px;
                    display: block;
                    margin-bottom: 10px;
                    z-index: 1;
                    transition: 0.2s ease;
                    transform: translateY(0);
                    transition-property: "transform";
                }

                .menu-title {
                    color: #c4c4c4;
                    font-size: 16px;
                    font-weight: 500;
                    display: block;
                    z-index: 1;
                }

                &:hover {
                    box-shadow: 0 42px 60px 0 rgba(90, 113, 208, 0.2);

                    &::after {
                        opacity: 1;
                    }

                    .menu-icons {
                        color: #fff;
                        transform: translateY(-5px);
                    }

                    .menu-title {
                        color: #fff;
                    }
                }
            }

            &.active {
                .nav-link {
                    background: linear-gradient(46deg, #0fadd0, #03bea5);

                    .menu-icons {
                        color: #fff;
                    }

                    .menu-title {
                        color: #fff;
                    }
                }
            }
        }
    }

    .sidebar-footer {
        background: #fff;
        box-shadow: 0 42px 60px 0 rgba(90, 113, 208, 0.11);
        padding: 40px 37px;
        display: block;
        border-radius: 8px;
        margin-top: 35px;


        .progress-wrapper {
            padding: 20px;
            border: 2px solid #ebeef7;
            border-radius: 4px;

            .progress-wrapper-inner {
                border-radius: 4px;

                .progress-title {
                    color: #27d2e3;
                    margin-bottom: 0;
                }

                .progress-balance {
                    margin-bottom: 0;
                    margin-left: 10px;
                }
            }

            .progress {
                background: #f2f2f2;
                margin-top: 5px;

                .progress-bar {
                    box-shadow: 0 42px 60px 0 rgba(90, 113, 208, 0.11);
                    background-image: linear-gradient(87deg, #2dc9eb, #14d2b8);
                }
            }
        }

        .upgrade-button {
            margin-top: 15px;
        }
    }

    .sidebar-footer-bottom {
        display: flex;
        flex-direction: column;

        ul {
            padding-left: 0;
            list-style: none;
            margin-top: 30px;
            margin-bottom: 20px;

            li {
                display: inline-block;
                border-right: 1px solid #c4c4c4;
                padding: 0px 10px;
                margin-right: 5px;
                line-height: 0;

                a {
                    color: #c4c4c4;
                    line-height: 1;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border-right: none;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }

        p {
            font-size: 15px;
            font-weight: 500;
            color: #c4c4c4;
        }
    }
}